export default {
    translation: {
        temporary: {
            type: {
                direct: 'direct',
                party: 'party',
            },
            rank: {
                customer: 'Customer',
                teamLeader: 'Team Leader',
                groupLeader: 'Group Leader',
                divisionalLeader: 'Divisional Leader',
                executiveLeader: 'Executive Leader',
            },
            bonusTotals: {
                centralTeam: 'Central team bonus',
                leadership1: 'Leadership 1',
                leadership2: 'Leadership 2',
                leadership3: 'Leadership 3',
                leadership4: 'Leadership 4',
                additionalSalesBonus: 'Additional sales bonus',
                productivityBonus: 'Productivity bonus',
                adjustments: 'Adjustments',
            },
            achievementAwards: {
                topPartnerParty: {
                    tooltip: 'Any Partner, any start date based on personal sales from party order types only',
                    title: 'Top Partner (Party)',
                    description: 'Personal Sales from Party orders',
                },
                topNewPartnerParty: {
                    tooltip: 'Partner who joined this year based on personal sales from party order types only',
                    title: 'Top Partner (Party)',
                    description: 'Personal Sales from Party orders',
                },
                topPartnerSchools: {
                    tooltip: 'Any Partner, any start date based on personal sales from school order types only',
                    title: 'Top Partner (Schools)',
                    description: 'Personal Sales from School orders',
                },
                topNewPartnerSchools: {
                    tooltip: 'Partner who joined this year based on personal sales from school order types only',
                    title: 'Top Partner (Schools)',
                    description: 'Personal Sales from School orders',
                },
                topLeader: {
                    tooltip: 'Any leadership, any start date based on personal team sales',
                    title: 'Top Leader',
                    description: 'New Recruit Sales',
                },
                topNewLeader: {
                    tooltip: 'Partners who joined this year based on personal team sales',
                    title: 'Top New Leader',
                    description: 'New Recruit Sales',
                },
                topRecruiter: {
                    tooltip: 'Any Partner, any start date based on points earned from QuickStart Achievements of Personal Recruits',
                    title: 'Top Recruiter',
                    description: 'Team QuickStart Achievements',
                },
                topNewRecruiter: {
                    tooltip: 'Partner who joined this year based on points earned from QuickStart Achievements of Personal Recruits',
                    title: 'Top New Recruiter',
                    description: 'Team QuickStart Achievements',
                },
                topTravelSeller: {
                    tooltip: 'Top Travel Incentive Sellers',
                    title: 'Top Travel Incentive Sellers',
                    description: 'Top Travel Incentive Sellers',
                },
                topTravelRecruiter: {
                    tooltip: 'Top Travel Incentive Recruiters',
                    title: 'Top Travel Incentive Recruiters',
                    description: 'Top Travel Incentive Recruiters',
                },
                topTravelPromoting: {
                    tooltip: 'Top Travel Promoting',
                    title: 'Top Travel Promoting',
                    description: 'Top Travel Promoting',
                },
                topTravelLeader: {
                    tooltip: 'Top Travel Incentive Leaders',
                    title: 'Top Travel Incentive Leaders',
                    description: 'Top Travel Incentive Leaders',
                },
                topTravelOverall: {
                    tooltip: 'TBC',
                    title: 'Top Travel Overall',
                    description: 'Top Travel Overall',
                },
            },
            trainingAssetCategories: {
                superStarterAcademy: 'Superstarter Academy',
                productExpert: 'Product Expert',
                socialMediaSuccess: 'Social Media Success',
                partyAndEvents: 'Party and Events',
            },
            marketingAssetCategories: {
                catalogs: 'Catalogs',
                promoVideos: 'Promo videos',
                promotionMaterial: 'Promotion material',
            },
            salePayment: 'STORAGE CREDIT',
        },
        ui: {
            money: '£',
            save: 'save',
            submit: 'submit',
            close: 'close',
            preview: 'preview',
            download: 'download',
            comingSoon: 'coming soon',
            create: 'create',
            remove: 'remove',
            optional: 'optional',
            send: 'send',
            select: 'select',
            current: 'Current',
            required: 'Required',
            explore: 'Explore',
            viewAs: 'View As',
            reupload: 'Reupload',
            search: 'Search',
            edit: 'Edit',
            backToApp: 'back to app',
            login: 'Login',
            goBack: 'Go back',
            resend: 'Resend',
            time: {
                label: 'time',
                timeZone: 'Time zone',
                short: {
                    day: {
                        mon: 'Mon',
                        tue: 'Tue',
                        wed: 'Wed',
                        thu: 'Thu',
                        fri: 'Fri',
                        sat: 'Sat',
                        sun: 'Sun',
                    },
                    month: {
                        jan: 'jan',
                        feb: 'feb',
                        mar: 'mar',
                        apr: 'apr',
                        may: 'may',
                        jun: 'jun',
                        jul: 'jul',
                        aug: 'aug',
                        sep: 'sep',
                        oct: 'oct',
                        nov: 'nov',
                        dec: 'dec',
                    },
                },
                long: {
                    day: {
                        mon: 'Monday',
                        tue: 'Tuesday',
                        wed: 'Wednesday',
                        thu: 'Thursday',
                        fri: 'Friday',
                        sat: 'Saturday',
                        sun: 'Sunday',
                    },
                    month: {
                        jan: 'January',
                        feb: 'February',
                        mar: 'March',
                        apr: 'April',
                        may: 'May',
                        jun: 'June',
                        jul: 'July',
                        aug: 'August',
                        sep: 'September',
                        oct: 'October',
                        nov: 'November',
                        dec: 'December',
                    },
                },
            },
            navigation: {
                open: 'Open navigation',
                close: 'Close navigation',
            },
            fileArea: {
                addDocument: 'Add document',
                placeholderTitle: 'Drop files here',
                placeholderSubtitle: 'Or use the "Add" button',
            },
            imageCrop: {
                alt: 'Source Image',
            },
            searchOptions: {
                noOptions: 'No options found',
            },
        },
        message: {
            invitationSent: 'Invitation sent successfully',
            invitationNotSent: 'Invitation was not sent',
            over18Message: 'You must be over 18 to become an Independent Partner',
            fieldPartOfKYC: 'This field is part of our KYC process',
            registerSuccess: 'Registered successfully',
            invitationResent: 'Email has been resent to your inbox.',
            updatedSuccessfully: 'Updated successfully',
            network: {
                connectionReestablished: 'Your network connection was re-established',
                networkError: 'It seems like you are having network connection issues',
            },
            file: {
                fileDownloading: 'Downloading {{fileName}}',
            },
        },
        tooltip: {
            firstName: 'Your first name as it appears on official documents. Please do not use abbreviations or nicknames.',
            lastName: 'Your surname as it appears on official documents.',
            birthDate: 'You must be over 18 to become an Independent Partner.',
            nameOfAccount: 'We ask for bank details so that we can pay commissions directly and securely into your account.',
            shippingAddress: 'The Address Name is what customers will see in their event order - we will not share the actual delivery address publicly.',
        },
        areaLabel: {
            goBack: '$t(ui.goBack)',
            progressOf: 'Progress of {{val}}',
            requirementFulfilled: 'Requirement fulfilled',
            requirementUnfulfilled: 'Requirement unfulfilled',
            facebook: 'Share through Facebook',
            twitter: 'Share through Twitter',
            whatsApp: 'Share through WhatsApp',
            scrollToTop: 'Scroll to Top',
            rankProgressTab: 'Rank Progress Tabs',
            info: 'Info',
            clipboard: {
                copyLink: 'Copy link',
            },
            datePicker: {
                previousMonth: 'Previous month',
                nextMonth: 'Next month',
            },
            expansionItem: {
                expandLess: 'Expand less',
                expandMore: 'Expand more',
            },
            pagination: {
                firstPage: 'First page',
                previousPage: 'Previous page',
                nextPage: 'Next page',
                lastPage: 'Last page',
            },
        },
        validation: {
            yearFormat: 'Valid year format is 19XX and 20XX',
            invalidOptionSelected: 'Invalid option selected',
            invalidForm: 'Form is invalid',
            requiredField: 'This field is required',
            invalidEmail: 'Invalid email address',
            eventCanceled: 'Event was cancelled successfully',
            eventDescriptionMaxLength: 'Description is too long',
            eventMinimumTimeFrame: 'Time period is too short. Minimum is 1 hour',
            emptyAddressAndVideoUrl: 'Event must either contain an address or a video conference url',
            businessDetails: 'Business name and account fields both should be filled or none',
            driverLicenseFormat: 'Driver license should use valid format',
            passwordMatch: 'Please make sure your passwords match',
            age18: 'We require users to be 18 years old or over',
            passportMrz: 'Passport MRZ number should use valid format',
            incompleteRequiredFields: 'There are incomplete required fields',
        },
        common: {
            year: 'Year',
            month: 'Month',
            week: 'Week',
            day: 'Day',
            dayLeftLower_one: '1 day left',
            dayLeftLower_other: '{{ count }} days left',
            date: 'Date',
            done: 'Done',
            details: 'Details',
            notDone: 'Not Done',
            all: 'All',
            na: 'N/A',
            firstName: 'First Name',
            lastName: 'Last Name',
            name: 'Name',
            email: 'Email',
            info: 'Info',
            id: 'Id',
            phone: 'Phone',
            phone2: 'Phone 2',
            phoneNumber: 'Phone Number',
            address: 'Address line',
            address2: 'Address line 2',
            currentAddress: 'Current Address',
            joinDate: 'Join date',
            performance: 'Performance',
            ps: 'Personal Sales',
            gv: 'Personal Team Sales',
            ov: 'Total Title Sales',
            teamSales: 'Team Sales',
            mentor: 'Mentor',
            enrollee: 'Enrollee',
            host: 'Host',
            passport: 'Passport',
            currentRank: 'Current rank',
            nextRank: 'Next rank',
            register: 'Register',
            agree: 'Agree',
            accept: 'Accept',
            accepted: 'Accepted',
            decline: 'Decline',
            declined: 'Declined',
            tentative: 'Tentative',
            setPassword: 'Set password',
            repeatPassword: 'Repeat password',
            country: 'Country',
            tnc: 'Terms and Conditions',
            invitation: 'Invitation',
            invite: 'Invite',
            invitationLink: 'Invitation link',
            facebook: 'Facebook',
            twitter: 'Twitter',
            whatsApp: 'WhatsApp',
            description: 'Description',
            quantity: 'Quantity',
            price: 'Price',
            salesCommission: 'Sales Commission',
            psVolume: 'PS Volume',
            total: 'Total',
            orderNumber: 'Order Number',
            originalOrderNumber: 'Original Order Number',
            reason: 'Reason',
            totalOrderValue: 'Total Order Value',
            deliveryCharge: 'Delivery Charge',
            creditUsed: 'Credit Used',
            birthDate: 'Birth Date',
            shareViaQRCode: 'share via QR code',
            inviteGuestsViaQR: 'Invite guests via QR code',
            scanWithCamera: 'Simply scan this with the camera on your mobile phone',
            accessDenied: 'Access denied',
            fileNotFound: 'File was not found',
            noFiles: 'Currently there are no files',
            signIn: 'Sign in',
            type: 'Type',
            orders: 'Orders',
            salesInWeek_one: 'Sales in 1 week',
            salesInWeek_other: 'Sales in {{ count }} weeks',
            products: 'Products',
            placeholder: 'Placeholder',
            logout: 'Logout',
            null: 'null',
            location: 'Location',
            loadMore: 'Load more',
            monthly: 'Monthly',
            weekly: 'Weekly',
            daily: 'Daily',
            personal: 'Personal',
            downline: 'Downline',
            today: 'Today',
            period: 'Period',
            documentType: 'Document Type',
            drivingLicense: 'Driving license',
            sortType: 'Sort Type',
            supportEmail: 'info@pts.com',
            canceled: 'Canceled',
            closed: 'Closed',
            title: 'Title',
            event: 'Event',
            customer: 'Customer',
            orderValue: 'Order value',
            commissionable: 'Commissionable',
            totals: 'Totals',
            to: 'to',
            me: 'Me',
            meUpper: 'Me',
            comingSoon: 'Feature will be available at a later time.',
            signUp: 'Sign up',
            shop: 'Shop',
            short: {
                ps: 'PS',
            },
            youCannotAccessThisPage: 'You cannot access this page',
            termsAndConditions: 'Terms & Conditions',
            bankingDetails: {
                accountName: 'Account Name',
                gb: {
                    bankSortCode: 'Account Sort Code',
                    bankAccountNumber: 'Account Number',
                },
                ie: {
                    bankSortCode: 'Bank SWIFT Code',
                    bankAccountNumber: 'Bank IBAN Number',
                },
            },
            management: 'Management',
            calendars: 'Calendars',
            registration: {
                steps: {
                    stepOne: 'Sign up',
                    stepTwo: 'Order your Kit',
                    stepThree: 'You\'re all set!',
                },
            },
            countries: {
                france: {
                    shortTitle: 'FR',
                    title: 'France',
                },
                germany: {
                    shortTitle: 'DE',
                    title: 'Germany',
                },
                italy: {
                    shortTitle: 'IT',
                    title: 'Italy',
                },
                spain: {
                    shortTitle: 'ESP',
                    title: 'Spain',
                },
                poland: {
                    shortTitle: 'PL',
                    title: 'Poland',
                },
                romania: {
                    shortTitle: 'RO',
                    title: 'Romania',
                },
                slovakia: {
                    shortTitle: 'SK',
                    title: 'Slovakia',
                },
                greatBritain: {
                    shortTitle: 'GB',
                    title: 'Great Britain',
                },
                unitedKingdom: {
                    shortTitle: 'UK',
                    title: 'United Kingdom',
                },
            },
        },
        view: {
            appInstall: {
                content1: 'Add as an app and use it outside of {{ browserName }}.',
                content2: 'To install this app: tap',
                content3: 'icon and then tap "Add to homescreen"',
            },
            auth: {
                returnButton: 'Back to login',
                tenant: 'Company <b>{{authFailMessage}}</b> is currently inaccessible, for more information contact the owners at one of the following emails: <br>{{wrappedContacts}}.',
                accountDisabled: 'Your account has no access to <b>{{authFailMessage}}</b> company. For more details contact an administrator at one of the following emails: <br>{{wrappedContacts}}.',
                signingOut: 'Signing out...',
            },
            becomeAmbassador: {
                title: 'Become a Partner',
                caption: 'Join Us to...',
                buttonInvitation: 'Apply today',
                content: `<li>
                            Unlock exclusive product discounts
                        </li>
                        <li>
                            Earn extra money
                        </li>
                        <li>
                            Sell amazing products
                        </li>
                        <li>
                            Work wherever and whenever suits you
                        </li>`,
            },
            businessShape: {
                title: 'Become an ambassador',
                legend: {
                    activeTitle: 'Active',
                    activeTooltip: 'Active - Has achieved £120+ sales in the current calendar month',
                    activeOnFileTitle: 'Active on File',
                    activeOnFileTooltip: 'Active on File - has achieved £120+ sales in any one of the previous three calendar months',
                    notActiveTitle: 'Not Active',
                    notActiveTooltip: 'Not active - has not achieved £120+ sales in any one of the previous three calendar months',
                },
                listItem: {
                    level: 'Level {{ level }}',
                    active: 'Active: {{ count }}',
                    activeOnFile: 'Active On File: {{ count }}',
                    notActive: 'Not Active: {{ count }}',
                },
                nothingToDisplay: 'There is no data to display',
            },
            dashboard: {
                title: 'Dashboard',
                linkInvite: 'Share shopping link',
                monthPerformance: {
                    totalSales: 'Total Event sales',
                    totalSalesExplain: 'D. Total Event Sales across organisation from Events Completed',
                    avgSalesPerEvent: 'Average sales per Event',
                    avgSalesPerEventExplain: 'E. All time event sales divided by total amount of events',
                    projectedSales: 'Projected Event sales',
                    projectedSalesExplain: 'Projected Event Sales = D + (B x E)',
                    eventsBooked: 'Events booked',
                    thisMonth: 'This month',
                    lastMonth: 'Last month',
                    thisMonthExplain: 'B. Total Events booked this month across organisation',
                    thisMonthSoFarExplain: 'A. Total Events completed so far this month across organisation',
                    lastMonthExplain: 'C. Total Events completed last month across organisation',
                    eventsCompleted: 'Events completed',
                    eventPerformance: 'Event performance',
                },
                pathway: {
                    programmeTitle: 'Leadership Programme',
                    lastSeason: 'Last Season',
                    nextSeason: 'Next Season*',
                    thisSeason: 'This season',
                    explainer: '* Your membership for next season will be confirmed after the incentive period closes',
                    club250Title: '250 club',
                    club250Tooltip: 'Achieve sales volume of £250+ in a calendar month to be part of the 250 club',
                    monthsInClub: 'Months in the club',
                    quickStartTitle: 'QuickStart Milestones',
                    travelIncentiveTitle: 'Travel Incentive',
                    competitionProgressTitle: 'Competition Progress',
                    incentivesTitle: 'Incentives',
                },
                rankContainer: {
                    currentTitle: 'Current title',
                    nextTitle: 'Next title',
                },
                ranking: {
                    achievementAwards: 'Achievement Awards',
                    noContent: 'No rankings were found',
                },
                recruitment: {
                    recruitingPerformance: 'Recruiting Performance',
                    newPersonalThisMonth: 'New Personal Recruits this month',
                    newPersonalTeamThisMonth: 'New Recruits in your Personal Team this month',
                    newInDownlineThisMonth: 'New Recruits in your Downline this month',
                    partnersInCommunity: 'No. of Partners in your Community',
                    totalPartnersInDownline: 'Total no. of Partners in your Downline',
                    partnersInPersonalTeam: 'No. of Partners in your Personal Team',
                },
                stats: {
                    activeOnFilePartnersTooltip_one: 'Monthly qualification for {{ rankLabel }} is {{ count }} Active on File personally sponsored Partner',
                    activeOnFilePartnersTooltip_other: 'Monthly qualification for {{ rankLabel }} is {{ count }} Active on File personally sponsored Partners',
                    ambassadorKitTooltip: 'You must have purchased the Starter Kit',
                    ambassadorLegsCountTooltip_one: 'Monthly qualification for {{ rankLabel }} is {{ count }} Active Partner in your Team',
                    ambassadorLegsCountTooltip_other: 'Monthly qualification for {{ rankLabel }} is {{ count }} Active Partners in your Team',
                    gvTooltip: 'Personal Team Sales is equal to all PTS sales less discount. Qualification is {{ count }}',
                    pvTooltip: 'Personal Sales is equal to total order less discount: {{ rankLabel }} qualification is {{ count }}',
                    pvTooltip150: 'Partner qualification requires minimum 120 Personal Sales',
                    ovTooltip: 'Total Title Sales for {{ rankLabel }} monthly qualification is {{ count }}',
                    uvTooltip: 'Unencumbered Personal Team Sales are equal to all PTS excluding sales of TL promoting downline partners. Qualification is {{ count }}',
                    qualifiedFrontlineLeadersCountTooltip_one: 'Monthly qualification for {{ rankLabel }} is {{ count }} Qualified 1st level Team Leader in your team.',
                    qualifiedFrontlineLeadersCountTooltip_other: 'Monthly qualification for {{ rankLabel }} is {{ count }} Qualified 1st level Team Leaders in your team.',
                    personalRecruitsCount_one: 'Monthly qualification for {{ rankLabel }} is {{ count }} Active Personal Partner in your team.',
                    personalRecruitsCount_other: 'Monthly qualification for {{ rankLabel }} is {{ count }} Active Personal Partners in your team.',
                    qualifiedBranches_one: 'Monthly qualification for {{ rankLabel }} is {{ count }} Qualified Partner Leg',
                    qualifiedBranches_other: 'Monthly qualification for {{ rankLabel }} is {{ count }} Qualified Partner Legs',
                    qualifiedLeaderLegsCount_one: 'Monthly qualification for {{ rankLabel }} is {{ count }} Qualified Leader Leg',
                    qualifiedLeaderLegsCount_other: 'Monthly qualification for {{ rankLabel }} is {{ count }} Qualified Leader Legs',
                    qualifiedOrgLeaderCountTooltip: 'Total Qualified Leaders in your organization',
                    metricNotRequired: 'This metric is not required for the title',
                },
            },
            downline: {
                title: 'Downline Explorer',
                noContent: 'Currently there are no partners in your downline.',
                progressTooltip: 'Progress to Team Leader rank',
                aofFilter: 'AoF type filter',
                level: 'level {{level}}',
                upToMentor: 'Up to $t(common.mentor)',
                upToMe: 'Back to me',
                filterOptions: {
                    all: 'All',
                    n1: 'N1',
                    n2: 'N2',
                    n3: 'N3',
                    n4: 'N4',
                    n5: 'N5',
                },
            },
            errorPage: {
                title: 'Looks like something went wrong.',
                mainPageButton: 'Main page',
                content: 'Head back to main page or logout',
            },
            eventInvite: {
                inviteTitle: 'Sign up to the event below',
                sentTitle: 'Invitation sent',
                sentContent: 'Check your email for event invitation link.',
                sendAnotherButton: 'Send another',
            },
            milestone: {
                consistent: 'Consistent',
                newLeader: 'New Leader Entries',
                activity: 'Activity',
                sales: 'Sales',
                recruiting: 'Recruiting',
                promoting: 'Promoting',
                leadership: 'Leadership',
                overall: 'Overall',
                businesDevelopment: {
                    gold: 'Gold member',
                    silver: 'Silver member',
                    bronze: 'Bronze member',
                },
                quickStart: {
                    milestonesLabel: 'QuickStart Milestones',
                    short: {
                        kitBoost: 'KB',
                        kitRefund: 'KR',
                        businessBoost: 'BB',
                        doublePromotion: 'DPB',
                    },
                    full: {
                        kitBoost: 'Kit Boost',
                        kitRefund: 'Kit Refund',
                        businessBoost: 'Business Boost',
                        doublePromotion: 'Double Promotion',
                    },
                },
                tooltips: {
                    pathway: {
                        travel: 'Travel Incentive',
                        business: 'Leadership Programme',
                        quick: 'QuickStart Milestones',
                        wildcard: 'Competition Progress',
                    },
                },
            },
            event: {
                campaignDate: 'Campaign date',
                eventDate: 'Event date',
                message: {
                    invitationToEmailSent: 'Event invitation has been sent to your email',
                    created: 'Event created successfully',
                    updated: 'Event updated successfully',
                },
                typeLabel: {
                    productShowcase: 'Product showcase',
                    getTogether: 'Get together',
                    campaignLaunch: 'Campaign launches',
                    generalMarketing: 'General meeting',
                    recruiting: 'Recruiting',
                },
                calendar: {
                    totalInvites: '{{ count }} invites sent',
                    totalSalesAmount: 'Total sales amount',
                    eventGuestsStatus: '{{goingGuests}} yes, {{tentativeGuests}} tentative, {{awaitingGuests}} awaiting, {{declinedGuests}} declined',
                    noEvent: 'There are no events today',
                    breakdown: '{{ val }} breakdown',
                    totalEvents: 'Total events',
                    salesAmount: 'Sales amount',
                    avgEventSales: 'Avg. sales per event',
                    avgCustomers: 'Avg. customers per event',
                },
                component: {
                    eventLocation: 'Event Location',
                    address: 'Address',
                    liveStreamTitle: 'Live Stream Shopping Link',
                    allowToInvite: 'Allow guests to invite guests',
                    shippingDetailsTitle: 'This is the default address for customers to have their event orders delivered to',
                    eventName: 'Event Name',
                    eventDetails: 'Event Details',
                    eventHost: 'Event Host',
                    eventType: 'Event type',
                    eventTakesPlace: 'Event takes place',
                    shopOnlineFrom: 'Shop online from',
                    shopOnlineFromHelper: 'Your online shop can be open before and after the event takes place, to receive orders related to said event.',
                    existingHost: 'Existing Host',
                    newHost: 'New Host',
                    noHost: 'No host',
                    noHostCaption: 'This event requires no Host',
                    deliveryDetails: 'Delivery Details',
                    descriptionPlaceholder: 'Add an event description',
                },
                create: {
                    title: 'Create Event',
                    whoIsHosting: 'Who is hosting the event?',
                    selectHostess: 'Select Hostess',
                    backToHost: 'Back to host selection',
                    existingHosts: 'List of existing hosts',
                    selectHost: 'Search host',
                    createEventTypeTitle: 'Create Event Type',
                    eventType: 'What type of an event are you creating?',
                    typeParty: 'Party or community event',
                    recruiting: 'Recruiting',
                    comingSoon: 'Coming Soon',
                    knownHostsList: 'List of known hosts',
                    editEventTitle: 'Edit Event',
                },
                invitation: {
                    myHostApp: 'My Host App',
                    loginTo: 'Login to $t(view.event.invitation.myHostApp)',
                    customerSignUpDescription: `Would you like to invite your friends?
            Click here to sign up for the party app,
            where you will be able to track all the parties you attend
            and receive notifications about exclusive deals.`,
                    invitedTo: 'You are invited to',
                    hostParty: 'host this party',
                    partnerForEvent: 'Partner for the event',
                    hostOfThisEvent: 'Host of this event',
                    confirmAndUnderstood: 'I confirm I have read and understood the',
                    declineInvite: 'Are you sure You want to decline this invite?',
                    buttonTooltip: 'To perform this action you must agree to terms and conditions',
                    inviteToEvent: 'Invite to event',
                    invitationFrom: 'Invitation from',
                    eventInvitation: 'Event invitation',
                },
                view: {
                    bonusLevel: 'Bonus level',
                    range: 'Range',
                    reward1: 'Reward 1',
                    reward2: 'Reward 2',
                    shopOnlineFrom: 'Shop Online from',
                    eventTakesPlace: 'Event Takes Place',
                    hostName: 'Host Name',
                    eventDescription: 'Event Description',
                    event: 'Event',
                    eventSummary: 'Event Summary',
                    editButton: 'Edit event',
                    cancelButton: 'Cancel event',
                    cancelEventMessage: 'Are you sure You want to cancel this event?',
                    inviteGuests: 'Invite guests',
                    invitations: 'Invitations',
                    hostNeedsToAccept: 'Host needs to accept this event first before guests can be invited',
                    inviteGuestsViaEmail: 'Invite guests via email',
                    invitesSent: '{{ count }} invites sent',
                    eventOrders: 'Event Orders',
                    noOrdersInEvent: 'Currently there are no orders associated with this event',
                    placeAnOrder: 'place an order',
                },
            },
            incomeSummary: {
                title: 'Income Summary',
                commissionStatements: 'Commission statements',
                annualTotals: 'Annual totals',
                rank: 'Rank (Career Level)',
            },
            invite: {
                title: 'Invite',
                shareLink: 'Share an invite link',
                emailInvite: 'Invite by email',
                scanQR: 'Scan QR code to get invited',
            },
            leaderLegs: {
                title: 'Leader Legs',
                levelShort: 'L{{level}}',
                level: 'level {{level}}',
                qualifiedLeg: 'Is a Qualified Team Leader leg',
                noData: 'Currently there are no leaders to display.',
                ariaLabel: {
                    expandLevel: 'Expand level',
                },
            },
            myCustomers: {
                title: 'My Customers',
                directTrue: 'Customer address book',
                directFalse: 'Customer orders',
                noData: 'You do not have any customers yet.',
                subscene: {
                    titleLoading: 'Customer orders',
                    title: '{{fullName}}\'s orders',
                },
                edit: {
                    title: '{{fullName}}\'s personal details',
                    titleLoading: 'Customer personal details',
                    editDetails: 'Edit customer personal details',
                },
            },
            myReports: {
                title: 'My Reports',
            },
            navigation: {
                impersonating: 'You are impersonating as ',
                section: {
                    home: 'Home',
                    downline: 'Downline',
                    earnings: 'My Earnings',
                    orders: 'My Orders',
                    events: 'Events Planner',
                    audience: 'My Audience',
                    account: 'My Account',
                },
                item: {
                    dashboard: 'Dashboard',
                    newEnrollees: 'My New Partners',
                    team: 'My Team',
                    leaders: 'My Leaders',
                    businessShape: 'My Business Shape',
                    downline: 'My Downline',
                    commissions: 'My Commissions',
                    reports: 'My Reports',
                    storeCredit: 'Store Credit',
                    orders: 'My Orders',
                    createEvent: 'Create an Event',
                    events: 'My Events',
                    shareLink: 'Share sign-up link',
                    customers: 'My Customers',
                    endImpersonating: 'End Impersonation',
                    profile: 'Personal Information',
                    logout: 'Logout',
                },
            },
            newStarters: {
                title: 'New Partners',
                enrolleeOrders: 'Orders Of {{ fullName }}',
                breakawayProgress: 'Progress to Team Leader',
                enrolleeAchievedBreakaway: 'This person has already achieved Team Leader title',
                breakawayAchieved: 'Achieved Team Leader',
                noData: 'Currently there are no New Partners to display.',
                description: {
                    personalSales: 'Requires {{ amount }} Personal Sales in {{days}} days period since joining',
                    newRecruits: 'Requires 2 New Recruits in {{days}} days period since joining',
                    doublePromotion: 'Requires Promoting to Team Leader Title in 3 calendar months since joining',
                },
                tooltip: {
                    rankAdvancement: 'Rank Advancement Bonus for is doubled if Team Leader title is achieved within the first 3 months of joining',
                },
            },
            orders: {
                title: 'Orders',
            },
            noPermission: {
                ambassadorKit: {
                    buyButton: 'Buy now',
                    loginText: 'You are logged in as {{name}}. You need to purchase the Starter Kit to proceed.',
                },
                suspended: {
                    message: 'Your account is temporary suspended. Contact support for more information.',
                },
                termsAndConditions: {
                    agree: 'I agree to the Terms and Conditions',
                },
                underInspection: {
                    confirmPrompt: 'Please re-confirm your bank account details so we can continue to pay your monthly override commissions',
                },
            },
            profile: {
                title: 'Profile',
                bankDetails: {
                    title: 'Bank details',
                },
                businessDetails: {
                    title: 'Business details',
                    baseAddressSelect: 'Invoice to Address',
                    propertyInfoDisplay: 'Current Invoice to Address',
                },
                termsAndConditions: {
                    readTheLatest: 'Read the latest {{ termsType }} $t(common.termsAndConditions).',
                    readDocument: 'Read document',
                },
                personalDetails: {
                    title: 'Personal details',
                },
            },
            register: {
                content: {
                    title: 'Join the community',
                    titleLoggedIn: 'We need some additional details from You',
                    confirmation: 'I confirm I have read and understood the',
                },
                customerRegistration: {
                    guestInfo: 'Join our App as a customer',
                },
                hostessRegistration: {
                    form: {
                        guestInfo: 'You must register before you can respond to event invitations',
                    },
                },
            },
            registerVerification: {
                title: 'Please provide additional information',
                drivingLicense: {
                    number: 'Driving license number',
                },
                passport: {
                    label: 'Passport MRZ number',
                    content: 'The MRZ number is the long number which contains digits, letters and chevrons at the bottom of a passport',
                    imgalt: 'MRZ example',
                },
            },
            reports: {
                title: 'Reports',
                commissionPeriod: 'Commission period',
                noReports: 'No reports available for this period',
            },
            risingStars: {
                title: 'My Team',
                header: {
                    sortPS: 'PS',
                    sortAOF: 'AOF',
                    sortBreakaway: 'Breakaway',
                },
                legsList: {
                    noTeam: 'Currently there is no team to display.',
                    progressToLeader: 'Progress to Team Leader rank',
                },
            },
            sendRegistration: {
                inviteTitle: 'Your sign up invitation',
                afterRegistration: {
                    emailSent: `
                            Email has been sent to
                            <span class="AfterRegistration__Email">{{email}}</span>.
                            Check your inbox!
                    `,
                    spamMessage: `
                            If you cannot find it, check your spam folder.
                            If you have not received an email, submit the form again
                            or contact us through $t(common.supportEmail)
                    `,
                    disabledForX: 'disabled for {{number}}s',
                },
            },
            storeCredit: {
                title: 'Store Credit',
                balance: 'Balance',
                transactions: {
                    title: 'Transactions',
                    table: {
                        date: 'Date',
                        orderNumber: 'Order Number',
                        description: 'Description',
                        change: 'Change',
                        empty: 'There are no transactions to display yet',
                    },
                },
            },
        },
        projectComponents: {
            addressSelect: {
                keepTyping: '{{count}} Matches. Continue typing for better suggestions',
                broadSearch: 'Your search was too broad, keep typing for a better result',
                label: 'Address',
            },
            agreedToReceiveEmail: {
                tooltip: `By uploading contact information, you will be able to send party
                        invites and invitations to join your team.
                        Your contacts will not be subscribed to marketing emails unless
                        they sign up separately. For more information please see your
                        Partnership Agreement.`,
                label: 'You must read terms before accepting.',
            },
            camera: {
                allowToUseCamera: 'Please allow app to use your camera in order to take a picture',
                takePhoto: 'Take a photo',
                retakePhoto: 'Retake a photo',
            },
            emailInvite: {
                disclaimer: `This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.`,
            },
            enrolleeLegItem: {
                ambassadorLegsDetails: {
                    noResults: 'Currently there are no Qualified Personal Team Partners to display.',
                },
                leaderLegsDetails: {
                    noResults: 'Currently there are no Qualified Leader Legs to display.',
                },
                status: {
                    y: '<span class="Status__TextColor--fulfilled">Y</span> Have placed £120+ of orders this month + AOF',
                    n1: '<span class="Status__TextColor--almost">N1</span> Placed £120+ of orders 1 month ago + AOF',
                    n2: '<span class="Status__TextColor--almost">N2</span> Placed £120+ of orders 2 months ago + AOF',
                    n3: '<span class="Status__TextColor--insufficient">N3</span> Placed £120+ of orders 3 months ago + Not AOF',
                    n4: '<span class="Status__TextColor--insufficient">N4</span> Placed £120+ of orders 4 months ago + Not AOF',
                    n5: '<span class="Status__TextColor--insufficient">N5</span> Placed £120+ of orders 5 or more months ago + Not AOF',
                    insufficient: '<span class="Status__TextColor--insufficient">Red</span> = Inactive',
                    almost: '<span class="Status__TextColor--almost">Amber</span> = Achieved',
                    fulfilled: '<span class="Status__TextColor--fulfilled">Green</span> = Active',
                    risingStarTooltip: '<span><span class="Status__TextColor--fulfilled">Green</span> = Achieved</span>',
                },
                myLeadersHeaderLabel: '{{ count }} Active Partners',
            },
            guestRegistrationForm: {
                tncLabel: 'I confirm I have read and understood the',
            },
            leaderLegsStatusTooltip: {
                content: `<span>
            Leader leg minimum qualification requires:
        </span>
        <span>
            Personal Sales more or equal to {{ps}}
        </span>
        <span>
            Qualified Partners more or equal to {{personalRecruits}}
        </span>
        <span>
            Personal Team Sales more or equal to {{gv}}
        </span>`,
            },
            orderHeaderTitle: {
                originalId: 'Original: #{{ referenceId }}',
                psPositive: '+ PS {{ ps }}',
                psNegative: '- PS {{ ps }}',
            },
            ordersList: {
                noOrders: 'There are no orders to display yet',
            },
            personalDetails: {
                hideFromSearch: 'Hide from search',
            },
            risingStarsStatusTooltipContent: {
                content: 'Partner qualification requires minimum {{ ps }} Personal Sales',
            },
            uploadDialogContent: {
                header: 'Upload profile picture',
            },
        },
        utils: {
            rankRequirementManager: {
                starterKit: 'Starter kit',
                personalRecruits: 'Active Personal Partners',
                qualifiedBranches: 'Active Partner Legs',
                activeOnFilePartners: 'Active on File Personal Partners',
                qualifiedFrontlineLeaders: '1st Level Qualified Team Leader Legs',
                pv: 'Personal Sales',
                gv: 'Personal Team Sales',
                uv: 'Unencumbered Personal Team Sales',
                ov: 'Total Title Sales',
                leaderLegs: 'Qualified Team Leader Legs',
                ambassadorLegs_one: 'Active Personal Partner',
                ambassadorLegs_other: 'Active Personal Partners',
                qualifiedLeaders: 'Qualified Leaders',
            },
        },
    },
};
