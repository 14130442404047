/* eslint-disable max-classes-per-file */
// eslint-disable-next-line import/no-cycle
import GraphQlMockServer from '@/utils/graphql-mock-server';
import axios from 'axios';
import env from '@/environment';
import { Impersonator, RequestCache } from '@plumtreesystems/utils';
import { Graph, GraphConfig } from './internal';

export default abstract class AbstractQueryResource<T> {
    protected abstract getQuery(options: any): string;

    protected abstract getCacheCondition(): number|null;

    protected abstract getName(): string;

    private mockGraphQl: boolean = env.VUE_APP_MOCK_GRAPHQL === 'true';

    private requestCache: boolean = env.VUE_APP_REQUEST_CACHE === 'true';

    private mockGraphQlServer = new GraphQlMockServer();

    protected dispatchEvent(event: string, data: any) {
        this.graph.emit(event, data);
    }

    private graph: Graph;

    private graphConfig: GraphConfig;

    constructor(graph: Graph) {
        this.graphConfig = graph.getGraphConfig;
        this.graph = graph;
    }

    private reqConf(params = { token: null }) {
        const headers: any = {
            'Content-Type': 'application/json',
        };

        const { token } = params;

        if (token) {
            headers.Authorization = `Bearer ${token}`;
        } else if (Impersonator.getImpersonateToken()) {
            headers.Authorization = `Bearer ${Impersonator.getImpersonateToken()}`;
        } else if (this.graphConfig.getToken() !== '') {
            headers.Authorization = `Bearer ${this.graphConfig.getToken()}`;
        }
        return { headers };
    }

    public async query(data: any = {}, options: any = {}, queryOptions: any = {}): Promise<T> {
        if (this.mockGraphQl) {
            await new Promise((resolve) => setTimeout(resolve, 400));
            const result = await this.mockGraphQlServer
                .execute(this.getQuery(queryOptions), { variables: data });
            if (result.data) {
                return result.data;
            }

            throw result;
        }

        const condition: number|null = this.getCacheCondition();

        if (this.requestCache && condition && RequestCache
            .loadFromCacheCheck(condition, this.getName(), this.getQuery(queryOptions))
        ) {
            return RequestCache.getCachedRequestData(this.getName());
        }

        if (!navigator.onLine) {
            await new Promise<void>((resolve) => {
                const interval = setInterval(() => {
                    if (navigator.onLine) {
                        clearInterval(interval);
                        resolve();
                    }
                }, 2000);
            });
        }

        return axios
            .post(
                `${this.graphConfig.getPath()}/graphql`,
                options.formData ? data : { query: this.getQuery(queryOptions), variables: data },
                this.reqConf(options),
            )
            .then((res) => {
                const requestData = res.data.data;

                if (this.requestCache && condition) {
                    RequestCache.setRequestData({
                        data: requestData,
                        name: this.getName(),
                        query: this.getQuery(queryOptions),
                        impersonateToken: Impersonator.getImpersonateToken(),
                    });
                }
                return requestData;
            })
            .catch((err) => {
                if (err.response?.status) {
                    this.dispatchEvent(`${err.response.status}`, err);
                }
                throw err;
            });
    }
}
